import { useCallback, useRef, useState } from 'react';
import { Arrow } from '@vfit/shared-icons';
import {
  ImageAtoms,
  ButtonSlide,
  CustomText,
  ContainerTopProduct,
  Button,
} from '@vfit/shared/atoms';
import { useRouter } from 'next/router';
import { Fade } from 'react-awesome-reveal';
import { useDeviceType } from '@vfit/shared/hooks';
import { Text, VfModal } from '@vfit/shared/components';
import { IGradientImage, ITextModal } from '@vfit/shared/models';
import { fonts, purify } from '@vfit/shared/themes';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  requireStaticImageAtomComponent,
  AppMobile,
  getVariantByActionStyle,
} from '@vfit/shared/data-access';
import { ITopHero } from './topHeroDynamic.models';
import {
  DivImgContainer,
  SlideProductContainer,
  SlideProductContentBottom,
  SlideProductContentTop,
  SlideProductNavigation,
  SlideProductText,
  SlideProductContent,
  ModalTitle,
  ModalContent,
  Title,
  Description,
  SlideButtonsContainer,
  Category,
  SlideProductLiteText,
  TitleLite,
  DescriptionLite,
} from './topHeroDynamic.style';

const TopHeroDynamic = ({
  backUrl,
  backLabel,
  title,
  image,
  imageMobile,
  action,
  actionClick,
  secondAction,
  secondActionClick,
  text,
  textAction,
  textActionClick,
  descriptionBottom,
  disclaimer,
  divider,
  lottieDescription,
  loadingDescription,
  isShort,
  category,
  actionNavigationUrl,
  secondActionNavigationUrl,
  isLiteMode,
}: ITopHero) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [modal, setModal] = useState<ITextModal>({
    show: false,
  });
  const { isMobile, isDesktop } = useDeviceType();
  const { push, asPath } = useRouter();
  const [purifiedText] = purify([text || '']);
  const [purifiedTitle] = purify([title || '']);
  const [purifiedCategory] = purify([category || '']);
  const marginController = isShort || false;
  const divRef = useRef<HTMLDivElement | null>(null);
  // const breadCrumbRef = useRef<HTMLDivElement | null>(null);
  // const haveBreadCrumb = breadCrumbRef?.current?.getAttribute('id') === 'breadCrumb';
  const haveHeaderLight =
    (divRef?.current?.parentNode?.previousSibling as HTMLElement)?.getAttribute('id') ===
      'header-lite' || !!document.getElementById('header-lite');

  const handleBack = useCallback(() => {
    if (backUrl) {
      window.open(backUrl, '_blank');
    } else {
      push(asPath.slice(0, asPath.lastIndexOf('/')), undefined, { shallow: true });
    }
  }, [asPath, backUrl, push]);

  const scrollToContent = () => {
    if (textRef?.current) {
      textRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const resetModal = () => {
    setModal({
      show: false,
      text: '',
      title: '',
      disableOrganizeText: false,
    });
  };

  if (isLiteMode) {
    return (
      <SlideProductLiteText>
        {purifiedTitle && (
          <TitleLite
            headerShort={isShort}
            className="title-dynamic-hero"
            dangerouslySetInnerHTML={{
              __html: purifiedTitle,
            }}
          />
        )}
        {purifiedText && (
          <DescriptionLite
            headerShort={isShort}
            className="description-dynamic-hero"
            dangerouslySetInnerHTML={{
              __html: purifiedText,
            }}
          />
        )}
      </SlideProductLiteText>
    );
  }

  return (
    <div ref={divRef}>
      <SlideProductContainer shortHero={isShort} haveDivider={!divider}>
        <SlideProductContent shortHero={isShort} headerLite={haveHeaderLight}>
          <SlideProductContentTop>
            {backLabel && (
              <SlideProductNavigation shortHero={marginController}>
                <button type="button" className="back-dynamic-hero" onClick={handleBack}>
                  <div>
                    <Arrow rotate={130} />
                  </div>
                  <span>{backLabel}</span>
                </button>
              </SlideProductNavigation>
            )}
            {purifiedCategory && (
              <Category
                headerShort={isShort}
                className="category-dynamic-hero"
                dangerouslySetInnerHTML={{
                  __html: purifiedCategory,
                }}
              />
            )}
            <Fade triggerOnce>
              <SlideProductText>
                {purifiedTitle && (
                  <Title
                    headerShort={isShort}
                    className="title-dynamic-hero"
                    dangerouslySetInnerHTML={{
                      __html: purifiedTitle,
                    }}
                  />
                )}
                {purifiedText && (
                  <Description
                    headerShort={isShort}
                    className="description-dynamic-hero"
                    dangerouslySetInnerHTML={{
                      __html: purifiedText,
                    }}
                  />
                )}
                <SlideButtonsContainer>
                  {action?.title && (
                    <Button
                      // actionClick is inhibited to avoid double url opening
                      onClick={() => !actionNavigationUrl && actionClick?.()}
                      href={actionNavigationUrl}
                      target={
                        actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined
                      }
                      name={`action_topHero_${action?.type || ''}`}
                      variant={getVariantByActionStyle(action?.style)}
                    >
                      {action?.title}
                    </Button>
                  )}
                  {secondAction?.title && (
                    <Button
                      // secondActionClick is inhibited to avoid double url opening
                      onClick={() => !secondActionNavigationUrl && secondActionClick?.()}
                      href={secondActionNavigationUrl}
                      target={
                        secondActionNavigationUrl && secondAction?.isBlank === 'true'
                          ? '_blank'
                          : undefined
                      }
                      name={`action_topHero_second_${secondAction?.type || ''}`}
                      variant={getVariantByActionStyle(secondAction?.style)}
                    >
                      {secondAction?.title}
                    </Button>
                  )}
                </SlideButtonsContainer>
              </SlideProductText>
            </Fade>
          </SlideProductContentTop>
          {!isMobile && !isShort && (
            <SlideProductContentBottom>
              <ButtonSlide
                onClick={scrollToContent}
                animated
                small
                smallSize={40}
                rotation={90}
                buttonColor="#fff"
                hoverColor="rgba(255, 255, 255, 0.8)"
                clickColor="rgba(255, 255, 255, 0.6)"
                name="action_goToBottom"
              />
            </SlideProductContentBottom>
          )}
        </SlideProductContent>
        <DivImgContainer isApp={AppMobile.checkIsApp()} absolute>
          <ImageAtoms
            key={!isDesktop ? imageMobile : image}
            gradient={IGradientImage.GRADIENT_TOP_LEFT}
            nameCard="slideProduct"
            image={image}
            imageMobile={imageMobile}
            isMobile={!isDesktop}
          />
        </DivImgContainer>
      </SlideProductContainer>

      <div
        style={{
          marginTop: !isMobile ? 80 : 0,
          marginBottom: !isMobile ? 80 : 0,
        }}
        ref={descriptionBottom ? null : textRef}
      >
        {lottieDescription && loadingDescription ? (
          <ContainerTopProduct>
            <Player
              src={requireStaticImageAtomComponent(lottieDescription).src}
              className="player-dynamic-hero"
              loop
              autoplay
            />
          </ContainerTopProduct>
        ) : (
          (descriptionBottom || divider) && (
            <Text
              textConfig={{
                textAlign: 'left',
                text: descriptionBottom || '',
                size: 36,
                sizeMobile: 20,
              }}
              textRef={textRef}
              divider={divider}
              disclaimer={disclaimer}
              actionText={textAction}
              actionClick={textActionClick}
            />
          )
        )}
      </div>

      <VfModal height={563} isOpen={modal.show} handleClose={resetModal} customFooter={undefined}>
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText
                fontFamily={fonts.exbold}
                size={36}
                lineHeight={45}
                textAlign="left"
                text={modal.title}
              />
            </ModalTitle>
          )}
          {modal.text && (
            <ModalContent>
              <CustomText
                size={18}
                lineHeight={24}
                textAlign="left"
                text={modal.text}
                disableOrganizeText={modal.disableOrganizeText}
              />
            </ModalContent>
          )}
        </>
      </VfModal>
    </div>
  );
};

export default TopHeroDynamic;
