import React from 'react';
import { AppMobile, getVariantByActionStyle } from '@vfit/shared/data-access';
import { ImageAtoms, CustomText, Button } from '@vfit/shared/atoms';
import { Container, Title, TextContainer, ButtonContainer, FrontLabel } from './cardInfo.style';
import { IInfoCard } from './cardInfo.model';

const CardInfo: React.FC<IInfoCard> = ({
  icon,
  title,
  description,
  iconSize,
  actionText,
  actionClick,
  frontLabel,
  actionType,
  actionStyle = 'secondary',
  actionIsBlank,
  disableMarginTop,
  actionNavigationUrl,
}) => {
  const getVariantMobile = () => {
    const variant = getVariantByActionStyle(actionStyle);
    if (variant === 'secondary') return 'mva:alt1';
    return 'mva:primary';
  };

  return (
    <Container
      data-name="card-info-container"
      iconSize={iconSize}
      isApp={AppMobile.checkIsApp()}
      disableMarginTop={disableMarginTop}
    >
      {frontLabel ? (
        <FrontLabel>{frontLabel}</FrontLabel>
      ) : (
        <div>
          {typeof icon === 'string' ? (
            <div className="wrapperIcon">
              <ImageAtoms nameCard="wrapperIcon" image={icon} key={icon} />
            </div>
          ) : (
            <p className="numberBox">{icon}</p>
          )}
        </div>
      )}
      <div className="bottom-container-card-info">
        <div>
          <Title className="card-info-title" type={typeof icon}>
            {title}
          </Title>
          <TextContainer className="card-info-text-container">
            {description && <CustomText text={description} textAlign="left" />}
          </TextContainer>
        </div>
        {actionText && actionClick && (
          <ButtonContainer>
            <Button
              onClick={() =>
                // actionClick is inhibited to avoid double url opening
                !actionNavigationUrl && actionClick()
              }
              href={actionNavigationUrl}
              target={actionNavigationUrl && actionIsBlank === 'true' ? '_blank' : undefined}
              name={`action_card_cardInfo_${actionType || ''}`}
              variant={
                AppMobile.checkIsApp() ? getVariantMobile() : getVariantByActionStyle(actionStyle)
              }
            >
              <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
                {actionText}
              </span>
            </Button>
          </ButtonContainer>
        )}
      </div>
    </Container>
  );
};
export default CardInfo;
