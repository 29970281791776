import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 23px 0 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 69px 0 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 80px;
  }
`;

export const FaqAccordionWrapper = styled.div`
  a {
    color: ${colors.$262626} !important;
    font-weight: 700;

    :visited {
      color: ${colors.$262626} !important;
    }

    :hover {
      color: ${colors.$e60000} !important;
    }
  }
`;

export const FaqShowMore = styled.div`
  .capitalize-first {
    text-transform: lowercase;
  }
  .capitalize-first::first-letter {
    text-transform: uppercase;
  }
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-top: 48px;
  }

  .button-slide-container {
    width: 279px;

    button,
    a {
      padding: 10.5px 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 216px;
    }

    @media (min-width: ${breakpoints.bigDesktop}) {
      width: 324px;
    }
  }
`;

export const Text = styled.div`
  div {
    margin-bottom: 0;

    p {
      margin-block: 0;
    }
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;
